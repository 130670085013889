import { config, namespace } from "./config";
import { dataLayer } from "./datalayer";
import { gti } from "./helpers";
import {addEvent, addLoadEventWithTimeout, fireCustomEvent} from "./events";
import {acceptsCookies} from "./storage";
import {isBot} from "./client_detection";

let attempts = 0,
    url = 'https://www.googletagmanager.com/gtm.js',
    loaded = false;

function exists(id){
    let container_exists = false;

    id = (id || '').toLowerCase().replace('gtm-', '');

    if (typeof window.google_tag_manager === 'object' && typeof google_tag_manager.dataLayer === 'object'){
        for (let prop in window.google_tag_manager){
            if (prop.toLowerCase().indexOf('gtm-'+id) === 0){
                container_exists = true;
            }
        }
    }

    return container_exists;
}

function load(id, resource){

    if (id){
        id = (id+'').replace('GTM-', '');
    }

    id = id || '5DC6B7';

    function performLoad(){
        if (attempts > 2){
            return;
        }

        attempts++;

        window[namespace][0] = dataLayer[0];

        dataLayer.push({event: 'loading_gtm'});

        (function(w, d, s, l, i) {w[l] = w[l] || [];w[l].push({'gtm.start': gti(), event: 'gtm.js'});let f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';j.async = true;j.src = resource||(url+'?id=' + i) + dl;

            //custom edit
            j.addEventListener('load', function() {
                fireCustomEvent('gtm_loaded');
            });
            //custom edit

            f.parentNode.insertBefore(j, f);})(window, document, 'script', namespace, 'GTM-'+id);

        setTimeout(function(){
            let loaded;
            for (let i=0; i<window[namespace].length; i++){
                if (window[namespace][i].event === 'gtm.load'){
                    loaded = 1;
                }
            }

            if (!loaded){
                dataLayer.push({event: 'reloading_gtm'});
                performLoad(id, config.utility_path+'/'+id+'/_m.js'+(namespace !== 'dataLayer' ? '?l='+namespace : ''));
            }
            else {
                attempts = 0;
            }
        }, 5000);
    }

    if (isBot || config.DNT || !acceptsCookies()){ //TODO include DNT in GTM container and handle there, and drop this exclusion. Not urgent.
        return;
    }

    if (exists(id)){
        return;
    }

    addLoadEventWithTimeout(performLoad, 6000);
}

function loadDefault(){
    if (isBot){
        return;
    }

    if (!config.isAdmin
        && (!config.isDev
            || (config.isEDF && config.isMemTool))
        && (config.main_site
            || config.isBlogs
            || config.isEDAF
            || config.isGP
            || (config.isEDF && config.isMemTool)
            || config.isBiz
            || config.isEDFCS
            || config.isSupplyChain
            || config.isImpact2024
            || config.isImpact2023
            || config.isImpact2022
            || config.isImpact2021
            || config.isImpact2020
            || config.isVision2030
            || config.isElectricFleet
            || config.isFSC
            || config.isVS
            || config.isNZA
            || config.isLandfills
        )
    ) {
        load();
    }
    else if (config.isMCAF
        || config.isMCAFC4
        || config.isElectricFleet
        || config.isMRD
        || config.isEU
        || config.isAAAClimate
    ){
        load('MX583C');
    }
    else if ((config.isLocal || config.isDev) && !config.isMethaneSat){
        load('TTRBWFS');
    }
}

function reset(){
    //https://developers.google.com/gtagjs/devguide/datalayer#reset
    if (window[namespace]){
        window[namespace].push(function() {
            this.reset();
        });
    }
}

addEvent('gtm_loaded', function(){
    dataLayer.push({event: 'gtm_loaded_callback'});
});

let checkForLoad = setInterval(function(){
    if (exists() && Array.isArray(window[namespace])){
        for (let i=0; i<window[namespace].length; i++){
            if (window[namespace][i].event === 'gtm.load'){
                clearInterval(checkForLoad);
                loaded = true;
                dataLayer.push({event: 'gtm_loaded'});

                break;
            }
        }
    }

    if (!loaded && gti(config.pageStartTime) > 30000){
        clearInterval(checkForLoad);
        dataLayer.push({event: 'gtm_not_loaded'});
    }
}, 50);

export {
    exists,
    load,
    loaded,
    loadDefault,
    reset,
    url
}