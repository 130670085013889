import { config } from "./config";
import {$id, $name, _ef, gti, $qsAll} from "./helpers";

function getHead(){
    return document.head || $name('head');
}

function getHeadAttr(s) {
    return config.HEADTAG ? config.HEADTAG.getAttribute(s) : null;
}

export function convertStringToDOM(html){
    let div = document.createElement('div');
    div.innerHTML = html;
    return div;
}

function forAll(elem, selector, process) {
    if (typeof process === 'undefined'){
        process = selector;
        selector = elem;
        elem = null;
    }

    if (typeof elem === 'string'){
        elem = $qs(elem);

        if (!elem){
            return;
        }
    }

    let items = (elem || document).querySelectorAll(selector);
    if (items) {
        for (let i = 0; i < items.length; i++) {
            process(items[i], i);
        }
    }
}

export function addElement(append, obj){
    let elem = document.createElement(obj.elem);

    if (obj.a === 'body'){
        obj.a = document.body;
    }

    if (!obj.a){
        return false;
    }

    for (let prop in obj) {
        if (['a', 'elem'].indexOf(prop) === -1 && !!obj[prop]) {
            if (['innerHTML', 'innerText', 'className'].indexOf(prop) > -1){ //https://code.tutsplus.com/articles/the-downside-of-using-innerhtml-to-manipulate-the-dom-and-some-alternatives--cms-106722
                elem[prop] = obj[prop];
            }
            else {
                elem.setAttribute(prop, obj[prop]);
            }
        }
    }

    if (append){
        obj.a.appendChild(elem);
    }
    else {
        obj.a.insertBefore(elem, obj.a.firstChild)
    }

    return true;
}

function removeElement(e){
    if (typeof e === 'string'){
        e = $qs(e);
    }

    if (e){
        try {
            e.parentNode.removeChild(e);
        } catch(e){}
    }
}

export function appendHTML(obj) {
    addElement(true, obj);
}

export function prependHTML(obj) {
    addElement(false, obj);
}

export function getHeadline(elem){
    let headline_text = null;

    if (elem){
        for (let i=1; i<7; i++){
            let headline = $qs('h'+i, elem);
            if (headline){
                let innerText = headline.innerText;
                if (innerText){
                    headline_text = headline.innerText.trim();
                    break;
                }
            }
        }
    }

    return headline_text;
}

function setAttrs(ele, arr){
    if (typeof arr === 'string'){
        arr = [arr];
    }

    for (let i=0; i<arr.length; i++){
        ele.setAttribute(arr[i][0], arr[i][1]);
    }
}

function hasClass(e, c) {
    let has = false,
        list = 'classList';

    if (e && e[list]) {
        for (let i = 0; i < e[list].length; i++) {
            if (e[list][i] === c) {
                has = true;
            }
        }
    }

    return has;
}

function toggleClass (elem, classname, cb) {
    cb = cb || _ef;

    if (typeof elem === 'string') {
        elem = $id(elem);
    }

    if (elem) {
        elem.classList.toggle(classname);
    }

    cb(elem); //not sure this is necessary
}

function $qs(selector, elem){
    if (typeof elem === 'string'){
        elem = $qs(elem);
    }

    if (elem && elem.querySelector){
        return (elem).querySelector(selector);
    }

    return (document).querySelector(selector);
}

export function insertHTMLBefore(html, e) {
    let node = document.createElement('div'),
        parent = e.parentNode;

    node.innerHTML = html; //https://code.tutsplus.com/articles/the-downside-of-using-innerhtml-to-manipulate-the-dom-and-some-alternatives--cms-106722
    parent.insertBefore(node, e);
}

export function insertHTMLAfter(html, e) {
    let node = document.createElement('div');
    node.innerHTML = html;
    e.parentNode.insertBefore(node, e.nextSibling);
}

export function getMetaTags(){
    let meta_tags = {},
        ldjson = $qs('script[type="application/ld+json"]'),
        icon = $qs('link[rel="shortcut icon"]') || $qs('link[rel="icon"]');

    forAll('meta', function(el){
        let name = el.getAttribute('property') || el.getAttribute('name'),
            value = (el.getAttribute('content')+'').replace(/&quot;/g, "'").replace(/"/g, "'");

        //main site keywords: '[node:field_focus_area_2020],[node:field_issues],[node:field_approach]'
        if (name === 'keywords' && (value+'').indexOf(',') > -1){
            let temp = value.split(',').filter(function(e){
                return (e !== null && e !== false && e !== "" && typeof e !== "undefined");
            });

            value = temp.join(',');
        }

        //next-head-count is used in classy studio
        if (name && ['next-head-count'].indexOf(name) === -1){
            meta_tags[name] = value;
        }
    });

    if (ldjson){
        meta_tags.ldjson = (ldjson.innerText+'').trim();
        try {
            meta_tags.ldjson = JSON.parse(meta_tags.ldjson);
        } catch(e){}
    }

    if (icon && icon.href){
        meta_tags.icon = icon.href;
    }

    return meta_tags;
}

function getParentNode(elem, selector) {
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) return elem;
    }

    return null;
}

function getSiblings(e, opts) {
    let siblings = [];

    opts = opts || {};

    if (!e || !e.parentNode) {
        return siblings;
    }

    let sibling = e.parentNode.firstChild,
        sibling_crossed = false;

    while (sibling) {
        if (sibling === e){
            sibling_crossed = true;
        }

        if (sibling.nodeType === 1
            && sibling !== e
            && (!opts.next || sibling_crossed)
            && (!opts.prev || !sibling_crossed)
        ) {
            if (!opts.filter || sibling.classList.contains(opts.filter)){
                siblings.push(sibling);
            }
        }

        sibling = sibling.nextSibling;
    }

    return siblings;
}

//from https://gomakethings.com/how-to-get-all-of-an-elements-siblings-with-vanilla-js/
// let getSiblings = function(elem) {
//     let siblings = [],
//         sibling = elem.parentNode.firstChild;
//
//     while (sibling) {
//         if (sibling.nodeType === 1 && sibling !== elem) {
//             siblings.push(sibling);
//         }
//
//         sibling = sibling.nextSibling
//     }
//
//     return siblings;
// };

//https://stackoverflow.com/questions/5728558/get-the-dom-path-of-the-clicked-a
function getDomPath(el) {
    if (typeof el === 'object' && 'get' in el && el.length) {
        el = el[0]; //de-jqueries the object
    }

    if (!el || !el.parentNode){
        return null;
    }

    let stack = [];

    while (el.parentNode !== null) {
        let sibCount = 0,
            sibIndex = 0;

        for (let i = 0; i < el.parentNode.childNodes.length; i++) {
            let sib = el.parentNode.childNodes[i];
            if (sib.nodeName === el.nodeName) {
                if (sib === el) {
                    sibIndex = sibCount;
                }

                sibCount++;
            }
        }

        let nodeName = el.nodeName.toLowerCase(),
            classname = el.className;

        if (classname && typeof classname === 'string'){
            classname = classname.trim().replace(/ /g, '.');
            if (classname){
                classname = '.'+classname
            }
        }
        else {
            classname = '';
        }

        if (el.hasAttribute('id') && el.id) {
            stack.unshift(nodeName + '#' + el.id + classname);
        }
        else if (sibCount > 1) {
            stack.unshift(nodeName +classname+ ':eq(' + sibIndex + ')');
        }
        else {
            stack.unshift(nodeName+classname);
        }

        el = el.parentNode;
    }

    return stack.slice(1); // removes the html element
}

function isinview(elem, strict){
    if (gti(config.pageStartTime) < 2500){
        return false;
    }

    if (typeof elem === 'string'){
        elem = $qs(elem);
    }

    if (!elem || typeof elem !== 'object' || typeof elem.getBoundingClientRect !== 'function'){
        return false;
    }

    let viewportHeight = window.innerHeight || document.documentElement.clientHeight,
        elemBounds = elem.getBoundingClientRect();

    if (strict){
        //https://vanillajstoolkit.com/helpers/isinviewport/
        return elemBounds.top >= 0 &&
            elemBounds.left >= 0 &&
            elemBounds.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            elemBounds.right <= viewportHeight;
    }
    //TODO add option measure some percent threshold
    else {
        //https://gist.github.com/jjmu15/8646226?permalink_comment_id=2927049#gistcomment-2927049
        return (elemBounds.top > 0 || elemBounds.bottom > 0) && elemBounds.top < viewportHeight;
    }
}

//lighter version to https://github.com/camwiegert/in-view
function inview(elem, cb, strict){
    if (isinview(elem, strict)){
        return cb();
    }

    let wait = setInterval(function(){
        if (isinview(elem, strict)){
            clearInterval(wait);
            return cb();
        }
    }, 1000);
}

function toggleDisplay(elem, mode){
    if (!elem || !elem.style){
        return;
    }

    elem.style.display = mode;
}

function show(elem, inline){
    toggleDisplay(elem, (inline?'inline-':'')+'block');
}

function hide(elem){
    toggleDisplay(elem, 'none');
}

function waitForElement(selector, trigger) {
    function checkIfExists(){
        return $qs(selector) && $qsAll(selector).length > 0;
    }

    if (checkIfExists()){
        return trigger();
    }

    let interval = setInterval(function () {
        if (checkIfExists()) {
            clearInterval(interval);
            trigger($qs(selector));
        }
    }, 50);

    setTimeout(function() {
        clearInterval(interval);
    }, 15000);
}

export {
    getHead,
    getHeadAttr,
    forAll,
    setAttrs,
    hasClass,
    toggleClass,
    $qs,
    getParentNode,
    getSiblings,
    removeElement,
    getDomPath,
    inview,
    toggleDisplay,
    show,
    hide,
    waitForElement
}