let edf_domain = '.edf.org',
    edfaction_domain = '.edfaction.org',
    mcaf_domain = '.momscleanairforce.org',
    cama_domain = '.cleanairmomsaction.org',
    mrd_domain = '.mississippiriverdelta.org',
    adestra_user_params = ['hash', 'unique_value', 'contact_id', 'contact_guid'],

    //MAKE SURE TO REDACT IN GA4 ADMIN. There are more but focus on these:
    //https://analytics.google.com/analytics/web/?hl=en#/a2998195p244239626/admin/streams/table/2026412872
    //https://analytics.google.com/analytics/web/?hl=en#/a2998195p289579054/admin/streams/table/2992258438
    //https://analytics.google.com/analytics/web/?hl=en#/a121164345p344434800/admin/streams/table/4323486062
    //https://analytics.google.com/analytics/web/?hl=en#/a53911923p322347966/admin/streams/table/3826112874
    //https://analytics.google.com/analytics/web/?hl=en#/a22145963p324123660/admin/streams/table/3850736254
    //https://analytics.google.com/analytics/web/?hl=en#/a27355104p325240673/admin/streams/table/3868746633
    pii_param_strings = ['username', 'email', 'fname', 'firstname', 'first_name', 'lname', 'lastname', 'last_name', 'full_name', 'fullname', 'phone', 'mobile', 'address', 'street', 'street1', 'street2', 'addr', 'city', 'ssn'],

    appended_parameters = ['_gl', '_ga', 'ut_id', 'ut_sid', 'ut_pid', 'landing_pg', 'landing_pg_1st_visit', 'source_1st_visit', 'subsource_1st_visit', 'conversion_pg_query', 'custom_source', 'custom_sub_source', 'custom_transfer', 'wtga'],
    utm_parameters = ['utm_source', 'utm_medium', 'utm_id', 'utm_campaign', 'utm_term', 'utm_keyword', 'utm_content'],
    ub_parameters = ['ub_o', 'ub_tc', 'ub_tg', 'ub_cta', 'ub_f', 'ub_b', 'ub_fb'], //ub_b and ub_fb are just hedging against change on ub_f
    doc_extensions = 'doc|docx|pdf|ppt|txt|xls|xlsx',
    scriptName = document.currentScript && document.currentScript.src ? document.currentScript.src : null;

let constants = {
    V: '1.1.6',
    scriptName: scriptName,
    isLegacyJS: (scriptName+'').indexOf('e_tracking') > -1,
    GADEV: '24797801-1',
    GAPROD: '2998195-1',
    convioDomain: 'membership.onlineaction.org',
    P2A_hosts:['act'+edf_domain, 'act'+edfaction_domain, 'act'+mcaf_domain, 'act'+cama_domain, 'act'+mrd_domain],
    EA_hosts:['secure.everyaction.com', 'actnow'+edf_domain, 'actnow'+edfaction_domain, 'actnow'+mcaf_domain, 'actnow'+cama_domain, 'actnow'+mrd_domain],
    adestra_hosts:['email'+edf_domain, 'email'+edfaction_domain, 'email'+mcaf_domain, 'email'+cama_domain, 'email.mississippiriverdelta.org', 'environmental-defense-fund.msgfocus.com'],
    classy_hosts:['donate'+edf_domain, 'donate.sandbox'+edf_domain, 'donate'+edfaction_domain, 'donate.pac'+edfaction_domain, 'donate.txpac'+edfaction_domain, 'donate'+mcaf_domain, 'donate'+cama_domain],
    social_sources: ['facebook', 'twitter', 'linkedin', 'instagram', 'tiktok'],
    utm_parameters: utm_parameters,
    ub_parameters: ub_parameters,
    link_params: appended_parameters,
    pii_param_strings: pii_param_strings,
    exclude_params: appended_parameters.concat(utm_parameters).concat(ub_parameters).concat(pii_param_strings).concat(['nkey', 'sentto', 's', 'year', 'template', 'archive', 'campaign', 'filtered', 'calcid', 'start', 'searchselect', 'idb', 'i', 'JServSessionIdr004', 'fb_xd_fragment', 'ref_num', 'fb_ref', 'autologin', 'set.billingzip', 'set.BillingZip', 'gclid', 'home_city', 'matomy_subid', 'ce_cid', 'internal_source_code', 'wave_code', 'set.BillingCity', 'set.BillingState', 'set.billingcity', 'set.billingstate', 'cons_id', 'ftouch', 'wvsessionid', 'responseID', 'responseid', 'profile_city', 'profile_state', 'profile_postal_code', 'rtoken', 'sid', 'tagid', 'topicid', 'contentid', 'linkid', '_sm_byp', '_sm_au_', 'bset', 'key', 'preview_id', 'preview_nonce', 'tagId', 'contentId', 'sa', 'sqi', 'ved', 'mfc_pref', 'message_utm_id', 'NONCE_TOKEN', 'aud', 'state', 'clickimp', 'state', 'search', 'q', 'keys', 'accessToken', 'refreshToken', 'token', 'bucket', 'b', 't', 'zip5', 'prefix', 'fbclid', 'grab', 'msclkid', '_hsenc', '_hsmi', 'mc_cid', 'mc_eid', 'lset.billinglastname', 'mkt_tok', 'h_sid', 'hid', 'isappinstalled', 'hjdelay', 'contact_id', 'campaign_id', 'launch_id', 'contact_guid', 'unique_value', 'hash', 'title', 'email_variant', 'li_fat_id', 'li_giant', 'bxid', 'ueh', 'ibx_source', 'beid', 'tid', 'twclid', 'ltclid', 'dclid', 'eem', 'code', 'ga', 'eem', 'code', 'in_frame', 'jan1offset', 'jun1offset', 'mobile', 'width', 'height', 'bga', 'needsRedirect', 'csrf', 'hashed', 'uploadResume', 'global', 'invitee_uuid', 'answer_1', 'answer_2', 'rdt_cid', 'axios_adlink', 'cntr_auctionid', 'rname', 'mmdr']), //indicates parameters to exclude from ga page paths. tracked in repo:ga/ga_parameters_to_exclude.txt
    classy_src_params: ['c_src', 'c_src2'],
    adestra_user_params: adestra_user_params,
    adestra_params: ['campaign_id', 'launch_id', 'email_variant', 'utm_source', 'utm_campaign', 'utm_medium', 'utm_id', 'utm_content', 'bxid'],
    adestra_auth_params: ['campaign_id', 'launch_id'].concat(adestra_user_params),
    adestra_params_to_remove_if_empty: ['email_variant', 'contact_channel', 'contact', 'isc', 'wave_code', 'bxid', 'set.Value', 'other_amount', 'frequency'],
    params_to_keep: ub_parameters.concat(['ut_sid', 'ut_pid', 'ut_id']),
    custom: ['conversion_pg', 'landing_pg', 'landing_pg_1st_visit', 'source_1st_visit', 'subsource_1st_visit', 'addl_info', 'conversion_pg_query'],
    custom_strings: ['16', '17', '18', '19', '20', '21', '22'],
    us: ['www.momscleanairforce.org', 'momscleanairforce.org', 'cleanairmomsaction.org', 'momscleanair.wpengine.com'],
    EDFdomains: ['edf.org', 'edfaction.org', 'edfclimatecorps.org', 'onlineaction.org', 'electricfleet.org', 'giftplans.org', 'netzeroaction.org'/*, 'careers-edf.icims.com'*/],
    //NOTE edfclimatecorps.org is deprecated but keeping in case links persist
    //NOTE excluding careers-edf.icims.com for now since any parameters not owned by ICIMS don't actually make it to the pageview. no hook at the moment
    php_gift_redirects:['/donate', '/monthlygift', '/singlegift', '/donate-pac', '/honorgift'],
    lndo: 'edf.lndo.site',
    HN: location.hostname,
    PN: location.pathname,
    LN: location.protocol,
    HS: location.hash,
    page: location.href,
    docReferrer: document.referrer,
    doc_exts: doc_extensions,
    exts: doc_extensions+'|7z|aac|arc|arj|asf|asx|avi|bin|csv|exe|flv|gif|gz|gzip|hqx|jar|jpe?g|js|mp(2|3|4|e?g)|mov(ie)?|msi|msp|phps|png|qtm?|ra(m|r)?|sea|sit|tar|tgz|torrent|wav|wma|wmv|wpd|xml|z|zip|js|json|css|xml',
    cdnjs: 'https://cdnjs.cloudflare.com/ajax/libs/',
    CE: 'https://script.crazyegg.com/pages/scripts/0011/2468.js?'+Math.floor(new Date().getTime()/3600000),

    props_for_global: ['adestra_hosts', 'utility_path', 'assets_path', 'us', '_HPC', 'tracking_path', 'P2A_hosts', 'classy_hosts', 'user_id', 'current_page', 'custom', 'custom_strings', 'custom_transfers', 'custom_values', 'docHost', 'docPath', 'docReferrer', 'EDFdomains', 'emailTraffic', 'exclude_params', 'HEADTAG', 'HN', 'HNUpper', 'HS', 'known', 'LN', 'LS_enabled', 'main_site', 'page', 'pageStartTime', 'PN', 'QS', 'src', 'SS_enabled', 'subsrc'],

    DNT: ['1', 't', 'true', 'y', 'yes'].indexOf((navigator.doNotTrack+'').toLowerCase()) > -1 || location.href.indexOf('testing_DNT=true') > -1,
    userAgent: (navigator.userAgent || navigator.vendor || window.opera)+'', //TODO https://web.dev/migrate-to-ua-ch/, https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
    UTD: 'utility.edf.org',

    conversion_events: ['transaction', 'donation', 'purchase', 'action', 'signup', 'sign_up', 'mailchimp_signup', 'submit', 'submission', 'woobox_submit'],

    email_regex: new RegExp(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/),
    ssn_regex: new RegExp('[0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9][0-9][0-9]'),
    phone_number_regex: new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)
};

if (constants.PN[constants.PN.length-1] === ')'){
    window.location.href = constants.page.replace(constants.PN, constants.PN.replace(/\)/g, ''));
}

export {
    constants
}