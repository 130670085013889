import {config} from "./config";
import {gup, trim, isString} from "./helpers";
import * as ut from "./ut";

export function isLinkLink(link) {
    link = trim(link);
    return (isString(link) && link.indexOf('#') !== 0 && link.indexOf('javascript') !== 0 && !(link.indexOf('#') > -1 && link.split('#')[0] === config.LN+'//'+config.HN+config.PN));
}

export function isActionNetwork(str){
    return str.toLowerCase().replace(/%20/g, '').replace(/\+/g, '').replace(/ /g, '') === 'edfactionnetwork';
}

export function isEmailUrl(){
    let source = gup('utm_source'),
        medium = gup('utm_medium');

    return !!(source && medium && (isActionNetwork(source) || source === 'adestra' || gup('launch_id', config.page)) && medium.toLowerCase().indexOf('email') > -1);
}

export function isWebTraffic () {
    let source = gup('utm_source'),
        campaign = gup('utm_campaign'),
        medium = gup('utm_medium');

    return !(
        (source && (isActionNetwork(source) || source === 'gpad' || source === 'insider')) ||
        (medium && (medium.toLowerCase() === 'direct-mail' || medium.toLowerCase().indexOf('ads-dcm') > -1)) ||
        ((campaign && ((campaign.indexOf('pd_') > -1 && campaign.indexOf('_upd_dmt') === -1 && campaign.indexOf('_upd_web') === -1) || campaign.indexOf('gpad') === 0)) || campaign.indexOf('internal-edf') > -1 || campaign.indexOf('pd_acq') > -1) || ((medium+'').indexOf('email') === 0 && gup('launch_id', config.page)) || config.docReferrer.indexOf('insider') === 0 || config.isAdmin)
        || ut.getSess('webteam_traffic') === '1';
}

export function isMTF() {
    let campaign = gup('utm_campaign')+'';

    return gup('mtf') === '1'
        || campaign.indexOf('_dmt') > -1
        || campaign.indexOf('_pmt') > -1
        || ut.getSess('marketing_traffic') === '1';
}