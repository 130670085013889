import { config } from "./config";
import { dataLayer, push } from "./datalayer";
import {gup, $id, gti, addQryString, trimProtocol, isInt, _ef, enc, trimObject, booleanSniffer, numberSniffer, $qsAll, trim} from './helpers';
import {readCookie, createCookie, eraseCookie, LS_G, LS_R, LS_S, SS_G, SS_R, SS_S} from './storage';
import { addReadyEvent, forceChange, wait } from "./events";
import { NR } from './new_relic';
import { ajaxReq } from './ajax_req';
import * as ut from "./ut";
import { GAEvent } from "./ga";
import * as Base64 from "./base64";
import {$qs} from "./dom";

function saveContactData(json) {
    if (!json.id) {
        return wipe();
    }

    contact.data = json;

    let json_string = Base64.encode(JSON.stringify(json));
    LS_S(ss_key_name, json_string);
    SS_S(ss_key_name, json_string);

    adestraAuth = true;
    contact.ready = true;
    eraseCookie(cookie_block);

    ut.setUser('adestra_contact_id', json.id);
    dataLayer[0].adestra_auth = true;

    conditionalize();
}

function wipe() {
    adestraAuth = false;
    LS_R(ss_key_name);
    SS_R(ss_key_name);
    SS_R('amf_edf_subscriptions');
    createCookie(cookie_block, gti(), 0);
    contact.failed = true;

    NR.log('Adestra session wiped');

    dataLayer[0].adestra_auth = false;
}

function pingSession(cb) {
    cb = cb || _ef;

    ajaxReq({
        url: config.utility_path + 'adestra/session',
        withCredentials: true,
        timing: adestra_name+' session',
        ignore_error: true
    }, function(err, json) {
        if (!err && json && typeof json === 'object' && json.id) {
            json.session_pinged = new Date();
            saveContactData(json);

            NR.log("Adestra session ping " + json.id)
        }
        else {
            wipe();
        }

        cb(err, json);
    });
}

function bail_attempt_auth() {
    pingSession(function(err) {
        if (err) {
            wipe();
        }
    });
}

function cleanURL() {
    let qry = location.search;
    if (qry && window.history && window.history.replaceState) {
        let params_to_remove = params.concat(['ping']),
            params_to_remove_if_empty = config.adestra_params_to_remove_if_empty,
            temp;

        for (let i = 0; i < params_to_remove.length; i++) {
            temp = params_to_remove[i] + '=' + gup(params_to_remove[i]);
            qry = qry.replace('&' + temp, '').replace('?' + temp, '');
        }

        for (let i = 0; i < params_to_remove_if_empty.length; i++) {
            temp = params_to_remove_if_empty[i]+'=';
            if (!gup(params_to_remove_if_empty[i]) && qry.indexOf(temp) > -1){
                qry = qry.replace('&' + temp, '').replace('?' + temp, '');
            }
        }

        if (qry.indexOf('?') !== 0) {
            qry = ('?' + qry).replace('?&', '?');
        }

        if (qry === '?') {
            qry = '';
        }

        config.current_page = config.LN+'//'+config.HN+(config.isLocal&&location.port!=='80'?':'+location.port:'')+config.PN + qry + config.HS;

        if (!config.isClassy || config.is_classy_studio){ //Classy tanks when doing pushState or replaceState
            window.history.replaceState({}, document.title, config.current_page);
        }
    }

    url_cleaned = true;
}

function hasParams(){
    let state = true;
    for (let i = 0; i < params.length; i++) {
        if (!gup(params[i], config.page)) {
            state = false;
        }
    }
    return state;
}

function attempt_auth(opts) {
    if (!opts){
        contact.na = true;
        return;
    }

    opts.user = '';

    if (!hasParams()){
        contact.na = true;
        return;
    }

    if (!isInt(opts.campaign_id) || !isInt(opts.launch_id) || !isInt(opts.contact_id)) {
        return bail_attempt_auth();
    }

    let unique_value = opts.unique_value.split('.');
    if (unique_value.length !== 2 || !isInt(unique_value[1])) {
        return bail_attempt_auth();
    }

    unique_value = unique_value[0].split('-');
    if (unique_value.length !== 2 || !isInt(unique_value[1])) {
        return bail_attempt_auth();
    }

    ut.setSess({
        adestra_launch_id: opts.launch_id,
        adestra_campaign_id: opts.campaign_id,
        email_traffic: true
    });

    dataLayer[0].emailTraffic = true;

    ajaxReq({
        url: addQryString(config.utility_path + 'adestra/auth', opts),
        withCredentials: true,
        timing: adestra_name+' auth'
    }, function(err, json) {
        if (!err && json && typeof json === 'object' && json.id) {
            GAEvent(adestra_name, 'Auth', opts.contact_id);
            GAEvent('login', {
                ec: 'Adestra',
                el: opts.contact_id,
                method: 'email_autologin',
                at: 'email_autologin',
                af: opts.launch_id
            });

            ut.setUser('adestra_guid', opts.contact_guid);
            ut.setUser('email', json.email);
            ut.setUser('crid', json.crid);

            NR.log("Adestra session created: " + json.id);

            json.session_started = new Date();
            return saveContactData(json);
        }

        GAEvent(adestra_name, 'Auth fail', opts.contact_id);
        GAEvent('exception', {
            ec: 'Adestra',
            el: opts.contact_id,
            at: 'email_autologin',
            af: opts.launch_id
        });

        bail_attempt_auth();
    });
}

function logout(redirect) {
    let contact_id = getContact().id;

    if (contact_id){
        GAEvent(adestra_name, 'Logout', contact_id);
        GAEvent('logout', {
            ec: 'Adestra',
            el: contact_id
        });
    }

    wipe();
    wait(config.utility_path + 'adestra/logout?redirect='+enc(redirect||location.href), 500);

    // ajaxReq({
    //     url: config.utility_path + 'adestra/logout?redirect='+enc(redirect||location.href),
    //     withCredentials: true,
    //     timing: adestra_name+' logout'
    // }, function(err, json) {
    //     if (!err && json.status === 'inactive') {
    //         GAEvent(adestra_name, 'Logout', getContact().id || '');
    //         wipe();
    //     }
    // });
}

function populateFields(mapping, test_data) {
    addReadyEvent(function() {
        function search(id, type){
            id = id.replace(/\*/g, '');

            let test = $qsAll(type),
                elem = null;

            if (test){
                ['id', 'name', 'title'].forEach(function(prop){
                    if (elem){
                        return;
                    }

                    for (let j=0; j<test.length; j++){
                        if (test[j][prop] && (test[j][prop]+'').indexOf(id) > -1){
                            elem = test[j];
                        }
                    }
                });
            }

            return elem;
        }

        for (let prop in mapping) {
            let temp,
                value;

            if (prop.indexOf('*') > -1) {
                temp = search(prop, 'input') || search(prop, 'select') || fallbackObject;
            }
            else {
                temp = $id(prop) || $qs('[name*="'+prop+'"]') || $qs('[title*="'+prop+'"]') || fallbackObject;
            }

            if (typeof mapping[prop] === 'string') {
                value = (!!test_data ? test_data : contact.data)[mapping[prop]];
            }
            else {
                value = mapping[prop].transform((!!test_data ? test_data : contact.data)[mapping[prop].field]);
            }

            temp.value = value || temp.value;

            if (!temp.value && temp.tagName === 'SELECT') {
                for (let i = 0; i < temp.children.length; i++) {
                    let option = temp.children[i],
                        label = option.innerText,
                        val = option.value;

                    if (label && val && value && trim(label).toLowerCase() === trim(value).toLowerCase()) {
                        temp.value = val;
                    }
                }
            }

            if ((config.isEA || config.isClassy) && !!temp.value) {
                forceChange(temp);
            }
        }
    });
}

function conditionalize(test_data) {
    if (!Object.keys(contact.data).length) {
        if (!test_data || !Object.keys(test_data).length){
            return;
        }
    }

    //TODO handle state translations

    if (config.isAdestra){
        populateFields({
            'amf-input-first_name_*': 'fname',
            'amf-input-last_name_*': 'lname',
            'amf-input-address_*': 'street1',
            'amf-input-street_*': 'street1',
            'amf-input-street1_*': 'street1',
            'amf-input-street2_*': 'street2',
            'amf-input-city_*': 'city',
            'amf-input-state_*': 'state',
            'amf-input-zip_code_*': 'zip',
            'amf-input-email_*': 'email'
        });
    }
    else if (config.isEA){
        let waitingForEA = setInterval(function() {
            if ($qs('.ngp-form form')) {
                clearInterval(waitingForEA);

                populateFields({
                    'FirstName': 'fname',
                    'LastName': 'lname',
                    'AddressLine1': 'street1',
                    'AddressLine2': 'street2',
                    'City': 'city',
                    'State': 'state',
                    'PostalCode': 'zip',
                    'EmailAddress': 'email',
                    'Email': 'email'
                }, test_data);
            }
        }, 5);
    }
    else if (config.is_classy_studio){
        populateFields({
            'firstName': 'fname',
            'lastName': 'lname',
            'addressLine1': 'street1',
            'city': 'city',
            //'state': 'state',
            'zipCode': 'zip',
            'email': 'email'
        });
    }
    else if (config.isClassy){
        populateFields({
            'member_first_name': 'fname',
            'member_last_name': 'lname',
            'billing_address1': 'street1',
            'billing_city': 'city',
            'billing_state': 'state',
            'billing_postal_code': 'zip',
            'member_email_address': 'email'
        });
    }
}

function getContact() {
    return contact.data;
}

function isReady() {
    return (contact.ready || contact.failed || contact.na) && url_cleaned;
}

function isDone() {
    return isReady();
}

function onReady(cb) {
    if (isReady()) {
        return cb(contact.failed || contact.na, contact.data);
    }

    let waiting = setInterval(function() {
        if (isReady()) {
            clearInterval(waiting);
            cb(contact.failed || contact.na, contact.data);

            if (contact.data && contact.data.id){
                NR.setAttr('adestra_contact_id', contact.data.id);
                NR.setUid(contact.data.id);
            }
        }
    }, 1);
}

function onDone(cb) {
    if (isDone()) {
        return cb(contact.failed || contact.na, contact.data);
    }

    let waiting = setInterval(function() {
        if (isDone()) {
            clearInterval(waiting);
            cb(contact.failed || contact.na, contact.data);
        }
    }, 1);
}

let fallbackObject = {value: ''},
    params = config.adestra_auth_params,
    contact = {
        ready: false,
        failed: false,
        na: false,
        data: {}
    },
    adestra_name = 'Adestra',
    ss_key_name = 'edfcontact',
    cookie_block = 'edfacid_block',
    adestraAuth = false,
    url_cleaned = false;

onReady(function(){
    conditionalize();
    push({event: 'Adestra ready'});
});


function init(){
    let data = LS_G(ss_key_name) || SS_G(ss_key_name) || null;

    if (data) {
        let parsed = false;

        try {
            data = JSON.parse(data);
            parsed = true;
        } catch (e) {}

        if (!parsed){
            try {
                data = JSON.parse(Base64.decode(data));
                parsed = true;
            } catch (e) {}
        }

        if (!parsed){
            wipe();
        }
        else if (!data.session_started){
            data.session_started = new Date();
            saveContactData(data);
        }

        if (new Date()-data.session_started > 1000*3600*24*14){
            wipe();
        }
        else if (data.id) {
            if (data['45.hpc']){
                let hpc = parseInt((data['45.hpc']+'').replace('$', ''.replace(/,/g, '')));
                if (!isNaN(hpc)){
                    ut.setUser('hpc', hpc);
                }
            }

            contact.data = data;
            adestraAuth = true; //doesn't mean session is still active though on server
            contact.ready = true;
        }
    }

    let block = readCookie(cookie_block);

    if (block){
        block = parseInt(block);
        if (!isNaN(block) && block > 1 && gti(block) > 1000*60*60*24){
            eraseCookie(cookie_block);
        }
    }

    if (contact.ready){
        cleanURL();
    }
    else if (!contact.ready && !hasParams() && !readCookie(cookie_block) && (config.isMemTool || config.main_site)) {
        let ref_host = trimProtocol(config.docReferrer).split('/')[0];

        if (!config.isGDPR && (gup('ping') === 'session' || !config.docReferrer || (ref_host !== config.HN && config.us.indexOf(ref_host) > -1))) {
            pingSession(cleanURL);
        }
        else {
            contact.na = true;
            cleanURL();
        }
    }
    else if (!contact.ready) {
        let req = {
            utm_id: gup('utm_id')
        };

        for (let i = 0; i < params.length; i++) {
            req[params[i]] = gup(params[i]);
        }

        if (!config.isGDPR){
            attempt_auth(req);
        }
        else {
            contact.na = true;
        }

        cleanURL();
    }
    else {
        contact.na = true;
        cleanURL();
    }
}

function auth(){
    return adestraAuth;
}

function getContactFlags() {
    function extractIntegralCodes(val){
        if (!val){
            return null;
        }

        val = (val+'').trim().toLowerCase().split(' ');
        if (val.length !== 2 || isNaN(val[0])){
            return null;
        }

        return parseInt(val[0]);
    }

    let flags = {},
        data = getContact();

    if (data.id) {
        flags.ae = booleanSniffer(data.accept_email) ? 1 : 0;
        flags.s = data.state || null;
        flags.z = data.zip || null;

        flags.lf = booleanSniffer(data['39.leader_flag']) ? 1 : 0;
        flags.cd = data['39.house_rep_district'] || null;

        flags.hp = numberSniffer(data['45.hpc']);
        flags.lc = numberSniffer(data['45.lifetime_count']);
        flags.lr = numberSniffer(data['45.lifetime_revenue']);

        flags.ac = numberSniffer(data['47.lifetime_count']);

        flags.c4 = booleanSniffer(data['40.c4_member']) ? 1 : 0;
        flags.c4lo = data['40.c4_last_optin'] || null;
        flags.ec4 = booleanSniffer(data['40.edfaction_c4_member']) ? 1 : 0;
        flags.cc4 = booleanSniffer(data['40.cama_c4_member']) ? 1 : 0;

        flags.hvp = booleanSniffer(data['55.is_hvp']) ? 1 : 0;
        flags.ic = extractIntegralCodes(data['55.integral_cooperative']);
        flags.id = extractIntegralCodes(data['55.integral_demographic']);
        flags.wf = data['55.da_wealthfinder_code'] || null;

        flags.mc = data['55.da_motivation_category'] || null;
        flags.ms = data['55.da_motivation_score'] || null;

        flags.cr = data.crid || null;
    }

    flags = trimObject(flags, true);

    let flags_str = '';
    for (let flag in flags){
        flags_str += flag+':'+flags[flag]+'|';
    }

    return flags_str;
}

export {
    init,
    attempt_auth,
    getContact,
    conditionalize,
    pingSession,
    logout,
    auth,
    onReady,
    onDone,
    isReady,
    isDone,
    hasParams,
    populateFields,
    getContactFlags,
    cleanURL
}